<template lang="pug">
div.row
  div.col-12
    FileDropZone(v-if="checkAccess('recordBookStatement', 'add_file')" ref="mediaContent").w-100.my-4
  div.col-12
    v-btn(color="success" :loading="buttonLoader" @click="uploadFile").my-4 {{ $t('btn.save') }}
</template>

<script>
import { mapActions } from 'vuex'
import { checkAccess } from '@/mixins/permissions'
import { SUCCESS_CODE } from '../../../configs/constants'
export default {
  name: 'SailorRecordBookStatementUploadFiles',
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  data () {
    return {
      buttonLoader: false,
      checkAccess
    }
  },
  methods: {
    ...mapActions(['updateRecordBookStatement']),
    /** Upload file to record book statement */
    async uploadFile () {
      this.buttonLoader = true
      let data = {
        ...this.$route.params,
        media: {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'statement_service_record'
        }
      }
      const response = await this.updateRecordBookStatement(data)
      this.buttonLoader = false
      if (SUCCESS_CODE.includes(response.code)) this.$notification.success('AddedStatementRB')
    }
  }
}
</script>
